<template>
    <base-dialog width="722" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="6">
                        <base-input-label required
                            >Request Type</base-input-label
                        >
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            item-value="id"
                            item-text="name"
                            :items="requestTypes"
                            v-model="form.request_type_id"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6">
                        <base-input-label required
                            >Requested By</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            readonly
                            :value="form.requestedBy.name"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="8">
                        <base-input-label required
                            >Preferred Date & Time</base-input-label
                        >
                        <base-datetime-picker
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            :min="dateTimePickerMinDate"
                            v-model="form.preferred_datetime"
                        ></base-datetime-picker>
                    </v-col>

                    <v-col cols="12" md="4">
                        <base-input-label required>Status</base-input-label>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            item-value="id"
                            item-text="name"
                            :items="requestStatuses"
                            v-model="form.status"
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required
                            >Purpose of Visitation</base-input-label
                        >
                        <v-textarea
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.notes"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>

        <custom-search-dialog
            :is-open.sync="isSearchUserDialogOpen"
            title="Search User"
            :action="getUsersAction"
            @onSelect="onSelectUser"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { GET_USERS } from "@/store/modules/user";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import dateUtility from "@/mixins/date";
import { CREATE_REQUEST, UPDATE_REQUEST } from "@/store/modules/request";

const defaultForm = {
    request_type_id: null,
    requestedBy: {
        id: null,
        name: null,
    },
    preferred_datetime: null,
    notes: null,
    status: null,
};

export default {
    name: "request-form-dialog",

    components: {
        BaseDatetimePicker,
        CustomSearchDialog,
        BaseInputLabel,
        BaseDialog,
    },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            error: null,
            isActionStart: false,
            form: Object.assign({}, defaultForm),
            isSearchUserDialogOpen: false,
            isSearchRequestTypeDialogOpen: false,
            getUsersAction: GET_USERS,
            requestID: null,
        };
    },

    computed: {
        requestStatuses() {
            return this.$store.state.profile.requestStatuses;
        },

        requestTypes() {
            return this.$store.state.profile.requestTypes;
        },

        dateTimePickerMinDate() {
            return dateUtility.currentDate();
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Request",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Request",
                    }
                );
            }

            return title;
        },
    },

    watch: {
        show(value) {
            this.dialog = value;
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                this.toDefaultFormData(value);
            }
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                this.resetForm();
            }
        },
    },

    methods: {
        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        async onCreate() {
            const payload = {
                ...this.form,
                group_leader_user_id: this.form.requestedBy.id || null,
                group_category_id: this.form.groupCategory.id || null,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_REQUEST,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "New request was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const payload = {
                requestID: this.requestID,
                request_type_id: this.form.request_type_id || null,
                preferred_datetime: this.form.preferred_datetime || null,
                purpose_of_visitation: this.form.notes || null,
                status: this.form.status || "New",
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_REQUEST,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "Request was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        onSelectUser({ id, prefix, first_name, last_name }) {
            const fullName = `${prefix} ${first_name} ${last_name}`;
            this.form.requestedBy = Object.assign(
                {},
                {
                    id,
                    name: fullName,
                }
            );
        },

        toDefaultFormData(payload) {
            const {
                id,
                status,
                request_type,
                requested_by,
                purpose_of_visitation,
                created_at,
            } = payload;
            let requestedBy = {
                id: null,
                name: null,
            };
            if (requested_by) {
                const { prefix, first_name, last_name } = requested_by;
                const fullName = `${prefix} ${first_name} ${last_name}`;
                requestedBy = Object.assign(
                    {},
                    {
                        id: requested_by.id,
                        name: fullName,
                    }
                );
            }
            this.requestID = id;
            this.form = Object.assign(
                {},
                {
                    ...this.form,
                    requestedBy,
                    status,
                    request_type_id: request_type.id,
                    preferred_datetime:
                        dateUtility.formatTimestampToStandardFormat(created_at),
                    notes: purpose_of_visitation,
                }
            );
        },
    },
};
</script>
