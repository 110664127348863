<template>
    <div>
        <v-container
            id="requests"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="6" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Profile Management</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedMinistryPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="profileItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 16rem; color: black !important"
                            clearable
                            @click:clear="table.pagination.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <!--                        <v-btn-->
                        <!--                            class="border-radius-25 px-5 mx-1"-->
                        <!--                            depressed-->
                        <!--                            rounded-->
                        <!--                            dark-->
                        <!--                            color="#2C423D"-->
                        <!--                            @click="openUserFormDialog('add')"-->
                        <!--                            >Add Request</v-btn-->
                        <!--                        >-->
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Filter by Request Type"
                            item-text="name"
                            item-value="name"
                            :items="requestTypes"
                            clearable
                            @click:clear="table.options.membership = null"
                            v-model="table.options.type"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Filter by Status"
                            :items="requestStatuses"
                            item-text="name"
                            item-value="name"
                            clearable
                            @click:clear="table.options.milestone = null"
                            v-model="table.options.status"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by Creation Date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="max-width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col>
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.requestedBy="{ item }">
                                <span>{{
                                    item.requested_by
                                        ? `${item.requested_by.first_name} ${item.requested_by.last_name}`
                                        : "--"
                                }}</span>
                            </template>
                            <template v-slot:item.submittedOn="{ item }">
                                <span>{{ formatDate(item.created_at) }}</span>
                            </template>
                            <template v-slot:item.notes="{ item }">
                                <span>{{
                                    item.additional_comments
                                        ? item.additional_comments
                                        : "--"
                                }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openMinistryInformationDialog(item)
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <request-form-dialog
            :show.sync="requestForm.show"
            :action="requestForm.action"
            :data="requestForm.data"
            @onSuccess="getRequests"
            @close="requestForm = { ...requestForm, show: false, data: null }"
        ></request-form-dialog>

        <ministry-information-dialog
            :show.sync="requestInformationDialog.show"
            selectedProfile="Requests"
            selectedMinistryPage="Requests"
            :loadedProfile="requestInformationDialog.data"
            @close="requestInformationDialog.show = false"
            @showUpdateForm="openUserFormDialog('edit')"
            v-if="requestInformationDialog.data"
        ></ministry-information-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.dateFrom"
            :end-date.sync="table.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import MinistryInformationDialog from "@/components/MainView/Ministry/modals/view-modal";
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import dateUtility from "@/mixins/date";
import { GET_REQUESTS } from "@/store/modules/request";
import { GET_PROFILE_REQUEST_TYPES } from "@/store/modules/profile";
import RequestFormDialog from "@/components/MainView/Ministry/Requests/forms/RequestFormDialog";

export default {
    name: "ministry-requests",

    components: {
        RequestFormDialog,
        MinistryInformationDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    dateFrom: null,
                    dateTo: null,
                    type: null,
                    status: null,
                    sortBy: "desc",
                },
                headers: [
                    {
                        text: "Request Type",
                        value: "request_type.name",
                        sortable: false,
                    },
                    {
                        text: "Requested By",
                        value: "requestedBy",
                        sortable: false,
                    },
                    {
                        text: "Submitted On",
                        value: "submittedOn",
                        sortable: false,
                    },
                    {
                        text: "Notes",
                        value: "notes",
                        sortable: false,
                    },
                    {
                        text: "Status",
                        value: "status",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            requestForm: { show: false, action: "", data: null },
            requestInformationDialog: { show: false, data: null },
            selectedMinistryPage: "/ministry/requests",
            profileItems: [
                {
                    text: "Users",
                    value: "/ministry/users",
                },
                {
                    text: "Groups",
                    value: "/ministry/groups",
                },
                {
                    text: "Requests",
                    value: "/ministry/requests",
                },
                {
                    text: "Announcements",
                    value: "/ministry/announcements",
                },
            ],
            show: false,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        requestStatuses() {
            return this.$store.state.profile.requestStatuses;
        },

        requestTypes() {
            return this.$store.state.profile.requestTypes;
        },
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getRequests();
            },
            deep: true,
        },
    },

    methods: {
        async getRequests() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_REQUESTS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getRequests();
        },

        openUserFormDialog(action) {
            switch (action) {
                case "add":
                    this.requestForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.requestForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.requestInformationDialog.data
                        ),
                    };
                    this.requestInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.requestForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        onOpenUpdateUserForm({ data }) {
            this.requestInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openUserFormDialog("edit");
        },

        formatDate(timestamp) {
            return dateUtility.toSimpleDate(timestamp);
        },

        async openMinistryInformationDialog(data) {
            this.requestInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        isVisitationRequest(type) {
            return type.id === 2; // Visitation Requests ID
        },

        async initiate() {
            this.selectedMinistryPage = this.$route.path;
            await this.getRequests();
            await this.$store.dispatch(GET_PROFILE_REQUEST_TYPES);
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
